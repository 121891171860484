import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  inject
} from '@angular/core';
import { UploadProgressService } from '@pinnakl/core/web-services';
import { FileDropDirective, FileSizePipe } from '@pinnakl/shared/ui/directives-and-pipes';
import { PrimeButtonComponent } from '@pinnakl/shared/ui/prime';
import {
  AttachmentService,
  BreakpointsService,
  InlineSVGModule,
  LayoutService,
  PinnaklUIToastMessage
} from '@pinnakl/shared/util-providers';
import { ProgressBarModule } from 'primeng/progressbar';
import { Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

export const ATTACHMENT_ALLOWED_EXTENSIONS = [
  'RAR',
  'ZIP',
  'PDF',
  'DOCX',
  'XLSX',
  'PPTX',
  'SVG',
  'PNG',
  'JPG',
  'JPEG'
];

export interface UploadAttachment {
  id: string;
  type: string;
  mimeType: string;
  fileSize: number;
  filename: string;
  displayName: string;
  file: File;
}

export interface UploadAttachmentsEvent {
  files: File[];
}

export enum FileSizes {
  MB100 = 104857600,
  MB10 = 10485760,
  MB1 = 1048576
}

@Component({
  standalone: true,
  selector: 'pinnakl-upload-dropzone',
  templateUrl: './upload-dropzone.component.html',
  styleUrls: ['./upload-dropzone.component.scss'],
  imports: [
    AsyncPipe,
    FileDropDirective,
    NgClass,
    InlineSVGModule,
    PrimeButtonComponent,
    ProgressBarModule,
    FileSizePipe,
    NgTemplateOutlet
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadDropzoneComponent {
  private readonly uploadProgressService = inject(UploadProgressService);
  private readonly toast = inject(PinnaklUIToastMessage);
  private readonly attachmentService = inject(AttachmentService);
  private readonly layoutService = inject(LayoutService);
  private readonly breakpointsService = inject(BreakpointsService);
  @Input() validExtensions: string[] = ATTACHMENT_ALLOWED_EXTENSIONS;
  @Input() multiple = true;
  @Input() maxFileSize = FileSizes.MB10; //10MB
  @Input() forceButton = false;
  @Input() attachments: UploadAttachment[] = [];
  @Input() isModalView = false;
  @Input() bordered = false;
  @Input() isNarrowView = false;
  @Input() disabled = false;
  @Input() contentClasses = '';
  @Input() containerClasses = '';
  @ContentChild('buttonTemplate', { static: false }) buttonTemplate: TemplateRef<null> | null =
    null;
  @Output() uploadAttachmentsEvent = new EventEmitter<UploadAttachmentsEvent>();
  @Output() removeAttachmentEvent = new EventEmitter<string>();
  public vm$!: Observable<{
    layout: { isMobile: boolean };
    progressMap: Map<string, number>;
  }>;

  get isDisabled(): boolean {
    return this.disabled;
  }

  constructor() {
    this.vm$ = combineLatest([
      this.uploadProgressService.progressMap$,
      this.layoutService.observeChanges$()
    ]).pipe(
      map(([progressMap, layout]) => ({
        progressMap,
        layout: {
          isMobile: this.breakpointsService.isSmallOrXSLayout(layout),
          value: layout
        }
      }))
    );
  }

  validateFileTypes(files: File[]): boolean {
    return files.every(({ name }) =>
      this.validExtensions.some(ext => name.toUpperCase().includes(`.${ext}`))
    );
  }

  handleUploadError(error: 'maxFileSize' | 'fileTypes' | string[]): void {
    let errorMessage!: string;
    if (error === 'maxFileSize') {
      errorMessage = `File size should be less ${AttachmentService.getFileSize(this.maxFileSize, 0)}`;
    }
    if (error === 'fileTypes') {
      errorMessage = `Valid file types are ${this.validExtensions.join(',')}`;
    }
    this.toast.error(errorMessage ?? `Can't read the file`);
  }

  filesDropped(files: File[]): void {
    this.uploadAttachments(files);
  }

  uploadAttachments(files: File[]): void {
    const filesValid = this.validateFileTypes(files);
    if (!filesValid) {
      return this.handleUploadError('fileTypes');
    }
    this.uploadAttachmentsEvent.emit(this.buildFiles(files));
  }

  openSelectFileDialog(): void {
    this.attachmentService
      .openSelectFileDialog({
        accept: this.validExtensions.map(ext => `.${ext.toLowerCase()}`).join(','),
        maxFileSize: this.maxFileSize,
        multiple: this.multiple
      })
      .pipe(take(1))
      .subscribe({
        next: files => {
          const filesValid = this.validateFileTypes(files);
          if (!filesValid) {
            return this.handleUploadError('fileTypes');
          }
          this.uploadAttachmentsEvent.emit(this.buildFiles(files));
        },
        error: (err: string[]) => this.handleUploadError(err)
      });
  }

  private buildFiles(files: File[]): UploadAttachmentsEvent {
    return {
      files: files.map(
        file =>
          new File([file], file.name.replace(/_+/g, ' '), {
            type: file.type,
            lastModified: file.lastModified
          })
      )
    };
  }
}
